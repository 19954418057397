/**
 * .home-panel-intro-wrapper.
 */

.home-panel-intro-wrapper {

    /**
     * Background.
     */

    // background-color: palegoldenrod;

    /**
     * .text-wrapper.
     */

    .text-wrapper {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Sizing.
         */

        width: 79%;

        /**
         * p.
         */
    
        p {
            
            /**
             * Font related.
             */
    
            font-size: 22px;
            line-height: 27px;
    
        }

    }

}
