/**
 * .home-panel-iconinfoblocks-wrapper.
 */

.home-panel-iconinfoblocks-wrapper {

    /**
     * Background.
     */

    background-color: $brand-four;

    /**
     * .col-block.
     */

    .col-block {

        /**
         * Padding.
         */

        padding-top: 20px;
        padding-bottom: 20px;

        /**
         * Font related.
         */

        text-align: center;

        /**
         * .animated-icon.
         */

        .animated-icon {

            /**
             * Margin.
             */

            margin-bottom: 25px;

            /**
             * Sizing.
             */

            width: 90px;

        }

        /**
         * p:not(.heading).
         */

        p:not(.heading) {

            /**
             * Font related.
             */

            font-size: 18px;
            line-height: 24px;

        }

    }

}
