/**
 * .common-panel-pressreleaseslist-wrapper.
 */

.common-panel-pressreleaseslist-wrapper {

    /**
     * .container.
     */
    
    .container {
        
        /**
         * Position.
         */
    
        position: relative;

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Sizing.
             */

            height: 1px;

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;

            /**
             * Background.
             */

            background-color: rgba($brand-two, 0.2);

        }

    }

    /**
     * .press-release-item-teaser-wrapper.
     */

    .press-release-item-teaser-wrapper {

        /**
         * Display.
         */

        display: block;

        /**
         * Background.
         */

        // background-color: yellow !important;

        /**
         * Margin.
         */

        margin-bottom: 40px;

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * .details.
         */

        .details {

            /**
             * Display.
             */

            display: flex;
            
            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                color: $brand-two;
                font-size: 18px;

                /**
                 * Flex.
                 */

                flex-grow: 1;

            }

        }
        
        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            color: $brand-one;

        }

        /**
         * img, svg, object.
         */

        img, svg, object {

            /**
             * Sizing.
             */

            width: 65px;

            /**
             * Margin.
             */

            margin-right: 20px;
            margin-left: 25px;

            /**
             * Pointer.
             */

            pointer-events: none;
        
        }



        /**
         * &:hover.
         */

        &:hover {

            /**
             * .details.
             */

            .details {

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                }

            }

        }

    }

}
