/**
 * .home-panel-video-wrapper.
 */

.home-panel-video-wrapper {


    /**
     * .video-wrapper.
     */

    .video-wrapper {

        /**
         * Position.
         */

        position: relative;

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            max-width: 100%;

        }

        /**
         * 
         */

        .image {

            /**
             * Position.
             */

            position: relative;

            /**
             * Z-index.
             */

            z-index: 2;

        }

        /**
         * &.video-playing.
         */

        &.video-playing {

            /**
             * .video-player-trigger.
             */

            .video-player-trigger {

                /**
                 * Opacity.
                 */

                opacity: 0;

            }

        }

        /**
         * .video-player-trigger.
         */

        .video-player-trigger {

            /**
             * Position.
             */

            position: absolute;
            top: 5%;
            left: 15%;           
            right: 15%;
            bottom: 25%;
            
            /**
             * Transition.
             */

            transition: opacity 0.2s ease;

            /**
             * .play-icon.
             */

            .play-icon {

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Transition.
                     */

                    transition: fill 0.2s ease, stroke 0.2s ease;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * .play-icon.
                 */

                .play-icon {

                    /**
                     * polygon.
                     */

                    polygon {

                        /**
                         * SVG.
                         */

                        fill: $brand-one !important;
                        stroke: $brand-one !important;

                    }

                }

            }

        }

        /**
         * .play-icon.
         */

        .play-icon {

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;

            /**
             * Z-index.
             */

            z-index: 4;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

            /**
             * Opacity. 
             */

            opacity: 0.8;

            /**
             * Sizing.
             */

            width: 100px;

        }

        /**
         * .flatvideo-embed-container.
         */

        .flatvideo-embed-container {

            /**
             * Position.
             */

            position: absolute;
            top: 40%;
            left: 50%;

            /**
             * Z-index.
             */

            z-index: 3;

            /**
             * Sizing.
             */

            width: 69%;
            height: 75%;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

            /**
             * iframe.
             */

            iframe {

                /**
                 * Sizing.
                 */

                width: 100%;
                height: 100%;

            }

        }

        /**
         * .dot.
         */

        .dot {

            /**
             * Position.
             */

            position: absolute;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * 
                 */

                display: none;

            }
            
        }

        /**
         * .dot-1.
         */
        
        .dot-1 {
            
            /**
             * Position.
             */

            top: -50px;
            left: 0;
            
            /**
             * Sizing.
             */

            width: 235px;
            
            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Sizing.
                 */

                width: 160px;

            }

        }

        /**
         * .dot-2.
         */

        .dot-2 {

            /**
             * Position.
             */

            bottom: 50px;
            right: 50px;

            /**
             * Sizing.
             */

            width: 500px;

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Sizing.
                 */

                width: 300px;

                /**
                 * Position.
                 */

                bottom: 0px;
                right: 0px;

            }

        }

    }

}
