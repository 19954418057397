/**
 * .panel.
 */

.panel {

    /**
     * 
     */

}

/**
 * .panel-container.
 */

.panel-container {

    /**
     * 
     */

    &.panel-spacing-external-top-0 { margin-top: 0; }
    &.panel-spacing-external-top-1 { margin-top: 20px; }
    &.panel-spacing-external-top-2 { margin-top: 40px; }
    &.panel-spacing-external-top-3 { margin-top: 60px; }
    &.panel-spacing-external-bottom-0 { margin-bottom: 0; }
    &.panel-spacing-external-bottom-1 { margin-bottom: 20px; }
    &.panel-spacing-external-bottom-2 { margin-bottom: 40px; }
    &.panel-spacing-external-bottom-3 { margin-bottom: 60px; }

    /**
     * 
     */

    &.panel-spacing-internal-top-0 { padding-top: 0; }
    &.panel-spacing-internal-top-1 { padding-top: 20px; }
    &.panel-spacing-internal-top-2 { padding-top: 40px; }
    &.panel-spacing-internal-top-3 { padding-top: 60px; @include breakpoint($b-df-mobile-landscape) { padding-top: 30px; } }
    &.panel-spacing-internal-bottom-0 { padding-bottom: 0; }
    &.panel-spacing-internal-bottom-1 { padding-bottom: 20px; }
    &.panel-spacing-internal-bottom-2 { padding-bottom: 40px; }
    &.panel-spacing-internal-bottom-3 { padding-bottom: 60px; @include breakpoint($b-df-mobile-landscape) { padding-bottom: 30px; } }

}
