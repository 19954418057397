/**
 * .insight-body.
 */

.insight-body {

    /**
     * Margin.
     */

    margin-bottom: 100px;

    /**
     * .single-column-content.
     */

    .single-column-content {

        /**
         * Margin.
         */

        margin-bottom: 16px;

    }

    /**
     * .single-column-content-list.
     */

    .single-column-content-list {

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-left: 20px;

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style-type: disc;

            }

        }

    }

    /**
     * .single-column-content-button.
     */

    .single-column-content-button {

        /**
         * a.
         */

        a {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Font related.
                 */

                color: white;
                
            }

        }

    }

    /**
     * .single-column-content-image.
     */

    .single-column-content-image {

        /**
         * img.
         */

        img {

            width: 100%;

        }

    }

    /**
     * .single-column-content-table.
     */

    .single-column-content-table {

        /**
         * table.
         */

        table {

            /**
             * Sizing.
             */
            
            width: 100%;

            /**
             * th, td.
             */

            th, td {

                /**
                 * Padding.
                 */

                padding: 10px 10px 0 10px;

                /**
                 * Border.
                 */

                border: 1px solid rgba(0,0,0,0.2);

            }

        }


    }

}
