/**
 * .fourohfour-wrapper.
 */

.fourohfour-wrapper {

    /**
     * .fourohfour.
     */

    .fourohfour {

        /**
         * Padding.
         */

        padding-top: 300px;
        padding-bottom: 200px;

    }

}