/**
 * @mixin.
 */

@mixin mini-arrow-svg-background($color, $direction: right) {

    /**
     * Grab the rgb components from the $color.
     */

    $redHex:   dec-to-hex(red($color));
    $greenHex: dec-to-hex(green($color));
    $blueHex:  dec-to-hex(blue($color));

    @if $redHex   == '' { $redHex: '00' }
    @if $greenHex == '' { $greenHex: '00' }
    @if $blueHex  == '' { $blueHex: '00' }

    $hexString: $redHex + $greenHex + $blueHex;

    /**
     * Add the # as url encoded.
     */

    $hexForInlineSvg: '%23' + $hexString;

    /**
     * 
     */

    $svgData: "";

    /**
     * Concat the svg data with the new hex value.
     */

    @if $direction == left {

        /**
         * 
         */
       
        $svgData: "data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 78.74 120.88'%3E%3Cpolygon points='69.56 0 78.74 10.57 21.34 60.44 78.74 110.32 69.56 120.89 0 60.44 69.56 0' style='fill:"+$hexForInlineSvg+"'/%3E%3C/svg%3E";

    } @else if $direction == right {

        /**
         * 
         */

        $svgData: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 78.74 120.88'%3E%3Cpolygon points='9.18 120.89 0 110.32 57.4 60.44 0 10.57 9.18 0 78.74 60.44 9.18 120.89' style='fill:"+$hexForInlineSvg+"'/%3E%3C/svg%3E";

    }

    /**
     * Background.
     */

    background-image: url($svgData);
    background-repeat: no-repeat;

}
