/**
 * footer.
 */

footer {

    /**
     * Background.
     */

    background-color: $brand-two;

    /**
     * Font related.
     */
    
    color: #959494;
    
    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .container.
     */

    .container {
        
        /**
         * Padding.
         */
    
        padding-top: 60px;
        padding-bottom: 40px;
        
    }

    /**
     * p.
     */

    p {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Font related.
         */

        color: #959494;
        font-size: 14px;

    }

    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * Padding.
         */

        padding-bottom: 15px;

    }

    /**
     * .col-text.
     */

    .col-text {

        /**
         * p, ul li a.
         */

        p, ul li a {

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Font related.
                 */

                font-size: 12px;
                line-height: 12px;

            }

        }

    }

    /**
     * .col-social.
     */

    .col-social {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 10px;

            /**
             * Flex.
             */

            justify-content: flex-start;

        }

    }

    /**
     * .footer-links.
     */

    .footer-links {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * li.
         */

        li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * 
             */

            position: relative;

            /**
             * 
             */

            &::before {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                left: 0;
                top: 50%;

                /**
                 * Transform.
                 */

                transform: translateY(-50%);

                /**
                 * Sizing.
                 */

                height: 80%;
                width: 1px;

                /**
                 * Background.
                 */

                background-color: #959494;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Position.
                     */

                    top: 60%;

                }

            }

            /**
             * Padding.
             */

            padding-left: 5px;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: #959494;
                font-size: 14px;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-one;
                    text-decoration: none;

                }

            }

        }

    }

    /**
     * .logo-link.
     */

    .logo-link {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Sizing.
         */

        width: 240px;

    }

}
