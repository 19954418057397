/**
 * .row.back-button.
 */

    .row.back-button {

    /**
     * Padding.
     */

    padding-top: 10px;
    padding-bottom: 60px;

    /**
     * a.
     */

    a {

        /**
         * Display.
         */

        display: block;

        /**
         * Font related.
         */

        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        color: $brand-one;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            color: $brand-two;
            text-decoration: none;

        }

    }

}