/**
 * 
 */

html {

    /**
     * 
     */

    scroll-behavior: smooth;

}

/**
 * body.
 */

body {

    /**
     * 
     */
   
}

/**
 * html, body.
 */

html, body { 
    
    /**
     * Sizing.
     */

    height: 100%;

}

/**
 * body > footer.
 */

body > footer {

    /**
     * Position.
     */

    position: sticky;
    top: 100vh;

}

/**
 * .overflow-wrapper.
 */

.overflow-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Overflow.
     */ 

    overflow: hidden !important;

}

/**
 * .background-moose-wrapper.
 */

.background-moose-wrapper {

    /**
     * Position.
     */

    position: absolute;
    top: 200px;
    left: 50%;

    /**
     * Transform.
     */

    transform: translateX(-50%) ;

    /**
     * Z-index.
     */

    z-index: -1;

    /**
     * .background-moose.
     */

    .background-moose {
    
        /**
         * Sizing.
         */

        width: 2800px;
    
        /**
         * Opacity.
         */

        opacity: 0.5;

    }

}

/**
 * 
 */

.content-space.size-1 { height: 10px; }
.content-space.size-2 { height: 30px; }
.content-space.size-3 { height: 50px; }

/**
 * 
 */

.mb-extra-extra-large { margin-bottom: 40px; }
.mb-extra-large  { margin-bottom: 40px; }
.mb-large        { margin-bottom: 30px; }
.mb-medium-large { margin-bottom: 25px; }
.mb-medium       { margin-bottom: 20px; }
.mb-medium-small { margin-bottom: 15px; }
.mb-small        { margin-bottom: 10px; }
.mb-extra-small  { margin-bottom: 5px; }

/**
 * 
 */

@mixin font-color-brand-one   { color: $brand-one; }
@mixin font-color-brand-two   { color: $brand-two; }
@mixin font-color-brand-three { color: $brand-three; }

/**
 * 
 */

.background-color-brand-one { background-color: $brand-one; }
.background-color-brand-two { background-color: $brand-two; }
.background-color-brand-three { background-color: $brand-three; }

/**
 * 
 */

.display-none      { display: none; }
.display-block-xs  { @include breakpoint($bs-extra-small-only)  { display: block; } }
.display-block-sm  { @include breakpoint($bs-small-only)        { display: block; } }
.display-block-md  { @include breakpoint($bs-medium-only)       { display: block; } }
.display-block-lg  { @include breakpoint($bs-large-only)        { display: block; } }
.display-block-xl  { @include breakpoint($bs-extra-large-only)  { display: block; } }
.display-block-xxl { @include breakpoint($bs-extra-extra-large-only) { display: block; } }