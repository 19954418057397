/**
 * .insight-hero.
 */

.insight-hero {

    /**
     * Padding.
     */

    padding-top: 40px;

    /**
     * h1.
     */

    h1 {

        /**
         * Margin.
         */

        margin-bottom: 30px;

        /**
         * Font related.
         */

        font-size: 60px;
        font-weight: bold;
        color: $brand-one;


    }    

}
