/**
 * header.header.
 */

header.header {

    /**
     * Padding.
     */

    padding-top: 30px;
    padding-bottom: 30px;

    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * .logo-anchor.
         */

        .logo-anchor {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Sizing.
             */

            width: 400px;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * 
                 */

                width: 250px;
            }

        }

        /**
         * .image.
         */

        .image {

            /**
             * Sizing.
             */

            width: 100%;
    
        }

    }

    /**
     * .col-menu.
     */

    .col-menu {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;
        align-items: center;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 35px;

            /**
             * Flex.
             */

            justify-content: center;

            /**
             * ul.
             */

            ul {

                /**
                 * li.
                 */

                li {

                    /**
                     * &:first-child.
                     */

                    &:first-child {

                        /**
                         * Margin.
                         */

                        margin-left: 0;

                    }

                }

            }

        }

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Margin.
                 */

                margin-left: 30px;

                /**
                 * &.current_page_item.
                 */

                &.current_page_item {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Font related.
                         */

                        color: $brand-two;

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: '';

                            /**
                             * Position.
                             */

                            position: absolute;
                            top: 50%;
                            left: -18px;

                            /**
                             * Sizing.
                             */

                            width: 12px;
                            height: 12px;

                            /**
                             * Border.
                             */

                            border-radius: 12px;
                        
                            /**
                             * Background.
                             */

                            background-color: $brand-one;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                        }

                    }

                }

                /**
                 * a.
                 */

                a {
                    
                    /**
                     * Font related.
                     */
    
                    font-size: 19px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $brand-one;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        text-decoration: none;
                        color: $brand-two;

                    }

                }
                
            }

        }

    }

    /**
     * .col-contact.
     */

    .col-contact {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Position.
             */

            position: inherit;

        }

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: center;
        justify-content: center;

        /**
         * a.
         */

        a {

            /**
             * Position.
             */

            position: relative;

            /**
             * Display.
             */

            display: block;

            /**
             * Font related.
             */

            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            color: $brand-three;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * 
                 */

                position: absolute;
                top: 25px;
                right: 10px;

            }

            /**
             * span.
             */
        
            span {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Z-index.
                 */

                z-index: 2;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                text-decoration: none;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Sizing.
                     */

                    width: 300px;
                    height: 300px;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        width: 200px;
                        height: 200px;
                        
                    }

                }

            }

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Background.
                 */

                background-color: $brand-one;

                /**
                 * Sizing.
                 */

                width: 250px;
                height: 250px;

                /**
                 * 
                 */

                transition: width 0.2s ease, height 0.2s ease;

                /**
                 * Border.
                 */

                border-radius: 300px;

                /**
                 * Position.
                 */

                position: absolute;
                top: 50%;
                left: 50%;

                /**
                 * Transform.
                 */

                transform: translateX(-50%) translateY(-50%);

                /**
                 * Z-index.
                 */

                z-index: 1;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    width: 150px;
                    height: 150px;
                    
                }

            }

        }

    }

}