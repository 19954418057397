/**
 * .common-panel-herowithtext-wrapper .
 */

.common-panel-herowithtext-wrapper  {

    /**
     * Padding.
     */

    padding-top: 60px;
    padding-bottom: 40px;

    /**
     * h1.
     */

    h1 {

        /**
         * Font related. 
         */

        font-size: 64px;
        color: $brand-one;
        font-weight: 600;

    }

    /**
     * p.subheading.
     */

    p.subheading {

        /**
         * Margin.
         */

        margin-bottom: 20px;

        /**
         * Font related.
         */

        font-size: 24px;
        line-height: 31px;

    }
    
    /**
     * .text-wrapper.
     */

    .text-wrapper {

        /**
         * Margin.
         */

        margin-top: 25px;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 23px;
            line-height: 30px;

        }

    }    

}
