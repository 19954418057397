/**
 * .common-panel-hero-wrapper.
 */

.common-panel-hero-wrapper {

    /**
     * Padding.
     */

    padding-top: 120px;
    padding-bottom: 50px;

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */
        
        font-size: 24px;
        line-height: 31px;

    }

    /**
     * h1.
     */

    h1 {

        /**
         * Font related.
         */

        font-weight: bold;

    }

}
