/**
 * 
 */

@import '../abstracts/mixins/mini-arrow-svg-background';

/**
 * 
 */

@mixin link-with-mini-arrow() {

    /**
     * 
     */

    /**
     * Position.
     */

    position: relative;

    /**
     * Padding.
     */

    padding-right: 20px;

    /**
     * Sizing.
     */

    min-width: 14px;
    min-height: 14px;

    /**
     * Transition.
     */
    
    transition: color 0.2s ease;

    /**
     * 
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * Sizing.
         */

        width: 14px;
        height: 14px;

        /**
         * Position.
         */

        position: absolute;
        right: 0;
        top: 50%;

        /**
         * Transform.
         */

        transform: translateY(-50%);

        /**
         * Transition.
         */

        transition: transform 0.2s ease;

        /**
         * @include.
         */

        @include mini-arrow-svg-background($brand-two);

    }

}

/**
 * .link-with-mini-arrow.
 */

.link-with-mini-arrow {

    /**
     * 
     */

    @include link-with-mini-arrow();

    /**
     * &:hover.
     */

    &:hover {

        /**
         * &::after.
         */

        &::after {

            /**
             * Transform.
             */

            transform: translateX(5px) translateY(-50%);

        }

    }

    /**
     * Arrow.
     */

    &.arrow-brand-one { &::after { @include mini-arrow-svg-background($brand-one); } }
    &.arrow-brand-two { &::after { @include mini-arrow-svg-background($brand-two); } }
    &.arrow-brand-three { &::after { @include mini-arrow-svg-background($brand-three); } }
    &.arrow-brand-five { &::after { @include mini-arrow-svg-background($brand-five); } }

    /**
     * Arrow:hover.
     */

    &.arrow-hover-brand-one { &:hover { &::after { @include mini-arrow-svg-background($brand-one); } } }
    &.arrow-hover-brand-two { &:hover { &::after { @include mini-arrow-svg-background($brand-two); } } }
    &.arrow-hover-brand-three { &:hover { &::after { @include mini-arrow-svg-background($brand-three); } } }
    &.arrow-hover-brand-five { &:hover { &::after { @include mini-arrow-svg-background($brand-five); } } }

}

/**
 * .link-with-mini-arrow-left.
 */

.link-with-mini-arrow-left {

    /**
     * 
     */

    @include link-with-mini-arrow();

    /**
     * Arrow.
     */

    &.arrow-brand-one { &::after { @include mini-arrow-svg-background($brand-one, left); } }
    &.arrow-brand-two { &::after { @include mini-arrow-svg-background($brand-two, left); } }
    &.arrow-brand-three { &::after { @include mini-arrow-svg-background($brand-three, left); } }
    &.arrow-brand-five { &::after { @include mini-arrow-svg-background($brand-five, left); } }

    /**
     * Arrow:hover.
     */

    &.arrow-hover-brand-one { &:hover { &::after { @include mini-arrow-svg-background($brand-one, left); } } }
    &.arrow-hover-brand-two { &:hover { &::after { @include mini-arrow-svg-background($brand-two, left); } } }
    &.arrow-hover-brand-three { &:hover { &::after { @include mini-arrow-svg-background($brand-three, left); } } }
    &.arrow-hover-brand-five { &:hover { &::after { @include mini-arrow-svg-background($brand-five, left); } } }
 
    /**
     * &:hover.
     */

    &:hover {

        /**
         * &::after.
         */

        &::after {

            /**
             * Transform.
             */

            transform: translateX(-5px) translateY(-50%);

        }

    }
    
}

