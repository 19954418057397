/**
 * 
 */

@mixin font-color-brand-one   { color: $brand-one; }
@mixin font-color-brand-two   { color: $brand-two; }
@mixin font-color-brand-three { color: $brand-three; }

/**
 * 
 */

.font-color-brand-one { @include font-color-brand-one(); }
.font-color-brand-two { @include font-color-brand-two(); }
.font-color-brand-three { @include font-color-brand-three(); }

/**
 * 
 */

@mixin font-extra-extra-large() { font-size: 64px; line-height: 70px; }
@mixin font-extra-large() { font-size: 45px; }
@mixin font-large() { font-size: 33px; }
@mixin font-medium-large() { font-size: 24px; line-height: 30px; }
@mixin font-medium() { font-size: 16px; line-height: 24px; }
@mixin font-medium-small() { }
@mixin font-small() { }
@mixin font-extra-small() { }

/**
 * 
 */

.font-extra-extra-large, .p-font-extra-extra-large { @include font-extra-extra-large(); }
.font-extra-large, .p-font-extra-large             { @include font-extra-large(); }
.font-large, .p-font-large                         { @include font-large(); }
.font-medium-large, .p-font-medium-large           { @include font-medium-large(); }
.font-medium, .p-font-medium                       { @include font-medium(); }
.font-medium-small, .p-font-medium-small           { @include font-medium-small(); }
.font-small, .p-font-small                         { @include font-small(); }
.font-extra-small, .p-font-extra-small             { @include font-extra-small(); }

/**
 * 
 */

.font-weight-bold { font-weight: bold; }

/**
 * 
 */

.text-align-center { text-align: center; }

/**
 * p.
 */

p {
    
    /**
     * @include.
     */

    @include font-medium();

    /**
     * Margin.
     */

    margin-bottom: 10px;

}

/**
 * h2.
 */

h2 {

    

}