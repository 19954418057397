/**
 * .splash-wrapper.
 */

.splash-wrapper {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Position.
     */

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    /**
     * Z-index.
     */

    z-index: 20;

    /**
     * @keyframe.
     */

    @keyframes circle {
        0% { clip-path: circle(75%); }
        100% { clip-path: circle(0%); }
    }

    /**
     * &.hiding
     */

    &.hiding {

        /**
         * Animation.
         */

        animation: circle 1s 1;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;

    }

    /**
     * &.showing-shape.
     */

    &.showing-shape {

        /**
         * .splash-shape.
         */

        .splash-shape {

            /**
             * Position.
             */

            top: 50%;

            /**
             * Opacity.
             */

            opacity: 1;

        }

    }

    /**
     * .showing-logo.
     */

    &.showing-logo {

        /**
         * .logo.
         */

        .logo {

            /**
             * Position.
             */

            top: 50%;

            /**
             * Opacity.
             */

            opacity: 1;

        }

    }

    /**
     * .shape.
     */

    .splash-shape {

        /**
         * Position.
         */

        position: absolute;
        top: 60%;
        left: 50%;

        /**
         * Sizing.
         */

        width: 65%;
        max-height: 65%;


        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transition.
         */

        transition: top 1s ease, opacity 1s ease;

    }

    /**
     * .logo.
     */

    .logo {

        /**
         * Position.
         */

        position: absolute;
        top: 60%;
        left: 50%;

        /**
         * Sizing.
         */

        width: 45%;
        max-height: 18%;

        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transition.
         */

        transition: top 1s ease, opacity 1s ease;

    }

    /**
     * lottie-player.
     */

    lottie-player {

        /**
         * 
         */

        @include breakpoint(max-height 650px)  { 
                    
            /**
             * 
             */
        
        }

        /**
         * 
         */

        @include breakpoint(min-width 1300px)  { 
                    
            /**
             * 
             */
        
        }

        /**
         * Position.
         */

        position: absolute;
        left: 50%;
        top: 50%;

        /**
         * Sizing.
         */

        // width: 70% ;
        height: 40% !important;


        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-20%);

        /**
         * Transition.
         */

        transition: transform 1s ease;

        /**
         * &.showing.
         */

        &.showing {

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

        }

        /**
         * svg.
         */

        svg {

            /**
             * Background.
             */

            background-color: rgba(white, 0.01);

        }        

    }

}
