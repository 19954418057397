/**
 * .insight-intro.
 */

.insight-intro {

    /**
     * Margin.
     */

    margin-bottom: 40px;

    /**
     * .image-container.
     */

    .image-container {

        /**
         * Background.
         */

        background-repeat: no-repeat;
        background-size: cover;

        /**
         * Sizing.
         */

        height: 500px;

    }

    /**
     * .date.
     */

    .date {

        /**
         * Margin.
         */

        margin-top: 25px;
        margin-bottom: 25px;

        /**
         * Font related.
         */
        
        font-size: 20px;
        color: rgba($brand-two, 0.5);

    }

    /**
     * .accreditation.
     */

    .accreditation {

        /**
         * Font related.
         */
        
        font-size: 12px;
        color: $brand-two;

    }

    /**
     * .subheading.
     */

    .subheading {

        /**
         * Font related.
         */

        font-size: 23px;
        line-height: 30px;
        font-weight: 400;

    }

    /**
     * img.
     */

    img {

        /**
         * Display.
         */

        display: none;

    }

}
