/**
 * mini-arrow-only--hover.
 */

@mixin mini-arrow-only--hover($direction: right) {

    /**
     * @if.
     */

    @if $direction == right {

        /**
         * Transform.
         */

        transform: translateX(5px);
        

    } @else if $direction == left {

        /**
         * Transform.
         */

        transform: translateX(-5px) rotate(180deg);

    }

}

/**
 * mini-arrow-only.
 */

@mixin mini-arrow-only($direction: right) {

    /**
     * Sizing.
     */

    width: 21px;
    height: 11px;

    /**
     * Transition.
     */

    transition: transform 0.2s ease;

    /**
     * line, polyline.
     */

    line, polyline {

        /**
         * Stroke.
         */

        stroke: #1e466f !important;

    }

    /**
     * @if.
     */

    @if $direction == left {

        /**
         * Transform.
         */

        transform: rotate(180deg);
        

    }

    // /**
    //  * &:hover.
    //  */

    // &:hover {

    //     /**
    //      * @include.
    //      */

    //     @include mini-arrow-only--hover($direction);

    // }

}

/**
 * mini-arrow-only--left.
 */

@mixin mini-arrow-only--left {

    /**
     * Transform.
     */

    transform: rotate(180deg);

}

/**
 * .mini-arrow-only.
 */

.mini-arrow-only {

    /**
     * @include.
     */

    @include mini-arrow-only();

}
 