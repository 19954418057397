/**
 * .button.
 */

.button {
 
    /**
     * Display.
     */

    display: inline-block;

    /**
     * Font size.
     */

    font-size: 15px;
    text-transform: uppercase;
    color: $brand-three;
    letter-spacing: 0.5px;
    font-weight: bold;

    /**
     * Border.
     */

    border: 1px solid $brand-three;

    /**
     * Padding.
     */

    padding: 18px 18px 17px 18px;

    /**
     * Transition.
     */

    transition: background-color 0.2s ease;

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        text-decoration: none;
        color: $brand-one;

        /**
         * 
         */

        background-color: $brand-three;

    }

    /**
     * &--extra-padding.
     */

    &--extra-padding {

        /**
         * Padding.
         */

        padding: 25px 30px 23px 30px;

    }

    /**
     * &--alt-style-1.
     */

    &--alt-style-1 {

        /**
         * Border.
         */

        border-color: $brand-one;

        /**
         * Font related.
         */

        color: $brand-two;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Border.
             */

            border-color: $brand-one;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Font related.
             */

            color: $brand-three;

        }

    }

    /**
     * &--alt-style-2.
     */

    &--alt-style-2 {

        /**
         * Border.
         */

        border-color: $brand-three;

        /**
         * Font related.
         */

        color: $brand-three;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Border.
             */

            border-color: $brand-two;

            /**
             * Background.
             */

            background-color: $brand-two;

            /**
             * Font related.
             */

            color: $brand-three;

        }

    }

    /**
     * &--alt-style-3.
     */

    &--alt-style-3 {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Border.
         */

        border-color: $brand-one;

        /**
         * Font related.
         */

        color: $brand-one;

        /**
         * 
         */

        padding-left: 20px;
        padding-right: 20px;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Border.
             */

            border-color: $brand-one;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Font related.
             */

            color: $brand-three;

        }

    }

}

/**
 * 
 */

