/**
 * .similar-articles-wrapper.
 */

.similar-articles-wrapper {

    /**
     * .subheading.
     */

    .subheading {
        
        /**
         * Margin.
         */

        margin-bottom: 20px;

        /**
         * Font related.
         */

        font-size: 24px;
        color: $brand-one;
        text-transform: uppercase;
        font-weight: bold;

    }

}

/**
 * .simiarl-article-wrapper.
 */

.simiarl-article-wrapper {

    /**
     * Display.
     */

    display: block;

    /**
     * Margin.
     */

    margin-bottom: 30px;

    /**
     * .image-container.
     */

    .image-container {

        /**
         * Background.
         */

        background-repeat: no-repeat;
        background-size: cover;

        /**
         * Sizing.
         */

        height: 200px;
        width: 100%;

    }

    /**
     * 
     */

    img {

        display: none;

    }

    /**
     * ul.
     */

    ul {

        /**
         * li.
         */
    
        li {

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                text-transform: uppercase;
                font-size: 10px;
                color: rgba($brand-two, 0.5);

            }

        }

    }

    /**
     * p.heading.
     */

    p.heading {

        /**
         * Font related.
         */

        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        color: $brand-one;

    }

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        text-decoration: none;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

}
