/**
 * .common-panel-insightslist-wrapper.
 */

.common-panel-insightslist-wrapper {

    /**
     * .container.
     */
    
    .container {
        
        /**
         * Position.
         */
    
        position: relative;

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Sizing.
             */

            height: 1px;

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;

            /**
             * Background.
             */

            background-color: rgba($brand-two, 0.2);

        }

    }

}

/**
 * #insights-posts-app.
 */

#insights-posts-app {

    /**
     * .paginator.
     */

    .paginator {

        /**
         * a.
         */

        a {

            /**
             * Display.
             */

            display: block;

            /**
             * Font related.
             */

            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            color: $brand-one;

            /**
             * Margin.
             */

            margin-top: 50px;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                color: $brand-two;
                text-decoration: none;

            }

            /**
             * &.disable.
             */

            &.disable {

                /**
                 * Display.
                 */

                display: none;

                /**
                 * Margin.
                 */

                margin-top: 0px;

                /**
                 * Opacity.
                 */

                opacity: 0;

            }

        }

    }

    /**
     * .insight-teaser-wrapper.
     */

    .insight-teaser-wrapper {

        /**
         * Display.
         */

        display: block;

        /**
         * Margin.
         */

        margin-bottom: 50px;

        /**
         * .image-container.
         */

        .image-container {

            /**
             * Background.
             */

            background-repeat: no-repeat;
            background-size: cover;

            /**
             * Sizing.
             */

            height: 200px;
            width: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                height: 300px;

            }

        }

        /**
         * img.
         */

        img {

            /**
             * Display.
             */

            display: none;

        }

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */
        
            li {

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */
    
                    text-transform: uppercase;
                    font-size: 10px;
                    color: rgba($brand-two, 0.5);

                }

            }

        }

        /**
         * p.heading.
         */

        p.heading {

            /**
             * Font related.
             */

            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
            color: $brand-one;

        }

        /**
         * p.
         */

        p {

            font-size: 18px;
            color: $brand-two;
            line-height: 24px;

        }

        /**
         * p.read-more.
         */

        p.read-more {

            /**
             * Font related.
             */

            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            color: $brand-one;

        }

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            text-decoration: none;

            /**
             * .teaser.
             */

            .teaser {

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                }

            }

        }

    }

}
