/**
 * @import.
 */

@import '_mini-arrow.scss';

/**
 * .paging-container.
 */

.paging-container {

    /**
     * Padding.
     */

    padding-top: 18px;

    /**
     * a.
     */

    a {

        /**
         * Display.
         */

        display: inline-block;

    }

    /**
     * .page-container.
     */

    .page-container {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * .page.
         */

        .page {
            
            /**
             * Padding.
             */

            padding-left: 5px;
            padding-right: 5px;
            
            /**
             * Margin.
             */

            margin-bottom: 0;

            /**
             * Font related.
             */

            font-size: 14px;

        }

    }

    /**
     * span.desc.
     */

    span.desc {

        /**
         * Display.
         */

        display: none;
        
    }

    /**
     * .prevnext-container.
     */

    .prevnext-container {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: center;

    }

    /**
     * .prev, .next.
     */

    .prev, .next {

        /**
         * Font related.
         */

        font-size: 14px;

        /**
         * span.
         */

        span {

            /**
             * Display.
             */

            display: none;

        }

    }

    /**
     * .prev.
     */

    .prev {

        /**
         * svg.
         */

        svg {

            /**
             * @include.
             */

            @include mini-arrow-only(left);

        }

    }

    /**
     * .next.
     */

    .next {

        /**
         * svg.
         */

        svg {

            /**
             * @include.
             */

            @include mini-arrow-only(right);

        }

    }

    /**
     * a.
     */

    a {

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            text-decoration: none;

        }

    }

}
