/**
 * .common-panel-two-column-content-wrapper.
 */

.common-panel-two-column-content-wrapper {

    /**
     * .row-heading.
     */

    .row-heading {

        /**
         * Margin.
         */

        margin-bottom: 30px;

    }

    /**
     * .row-content.
     */

    .row-content {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * 
             */

            // background-color: red;

        }        

        /**
         * .single-column-content-heading.
         */

        .single-column-content-heading {

            /**
             * Margin.
             */

            margin-top: 30px;

        }

    }

    /**
     * .col-left.
     */

    .col-left {

        /**
         * Padding.
         */

        padding-bottom: 60px;

        /**
         * Margin.
         */

        margin-top: -30px;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-bottom: 25px;

        }  

    }

    /**
     * .col-right.
     */

    .col-right {

        /**
         * 
         */

    }

}
