/**
 * .home-panel-ownershipmodels-wrapper.
 */

.home-panel-ownershipmodels-wrapper {

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */

        color: $brand-three;

    }

    /**
     * .button.
     */

    .button {

        /**
         * Margin.
         */

        margin-top: 15px;

    }

    /**
     * @include.
     */

    @include breakpoint($b-df-mobile-landscape) {

        /**
         * .paging-container.
         */

        .paging-container {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            justify-content: center;

        }

    }

    /**
     * .row-body.
     */

    .row-body {

        /**
         * Position.
         */

        position: relative;

        /**
         * Padding.
         */

        padding-top: 50px;
        padding-bottom: 40px;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 50px;

        }

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Position.
                 */

                left: -50%;
                right: auto;

                /**
                 * Sizing.
                 */

                width: 200%;

            }

            /**
             * Z-index.
             */

            z-index: 0;

            /**
             * Background.
             */

            background-color: $brand-one;

        }

    }

    /**
     * .dots-selector-list.
     */

    .dots-selector-list {

        /**
         * Margin.
         */

        margin-top: 35px;

        /**
         * li.
         */

        li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-right: 3px;

            /**
             * a.
             */

            a {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Sizing.
                 */

                width: 15px;
                height: 15px;

                /**
                 * Background.
                 */

                background-color: white;

                /**
                 * Border.
                 */

                border-radius: 15px;

                /**
                 * span.
                 */

                span {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Background.
                     */

                    background-color: #1f5c7f;

                }

            }

            /**
             * &.active.
             */

            &.active {

                /**
                 * a.
                 */

                a {

                    /**
                     * Background.
                     */

                    background-color: #1f5c7f;

                }

            }

        }

    }

    /**
     * .slide-selector-list.
     */

    .slide-selector-list {

        /**
         * Margin.
         */

        margin-bottom: 60px;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

        /**
         * li.
         */

        li {

            /**
             * Position.
             */

            position: relative;

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Padding.
             */

            padding-left: 14px;
            padding-right: 8px;

            /**
             * &.active.
             */

            &.active {

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: #1f5c7f;

                }

            }

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: $brand-three;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: bold;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: #1f5c7f;

                }

            }

            /**
             * &:first-child.
             */

            &:first-child {

                /**
                 * Padding.
                 */

                padding-left: 0;

                /**
                 * &::before.
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: none;

                }

            }

            /**
             * &::before.
             */

            &::before {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0;

                /**
                 * Sizing.
                 */

                width: 3px;

                /**
                 * Background.
                 */

                background-color: $brand-three;

                /**
                 * Transition.
                 */

                transition: background-color 0.2s ease;

            }

        }

    }

    /**
     * .col-icons.
     */

    .col-icons {

        /**
         * z-index.
         */

        z-index: 1;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * object.
             */

            object {

                /**
                 * Sizing.
                 */

                width: 70%;

            }

        }

    }

    /**
     * .col-slides.
     */

    .col-slides {

        /**
         * z-index.
         */

        z-index: 1;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 30px;

        }

    }

    /**
     * .icon-list.
     */

    .icon-list {

        /**
         * Position.
         */

        position: relative;

        /**
         * Margin.
         */

        margin-left: 40px;
        margin-right: 10px;
        margin-top: 40px;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-top: 10px;

        }

        /**
         * &::before.
         */

        &::before {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                width: 70%;

            }

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

            /**
             * Border.
             */

            border-radius: 200px;


            /**
             * Background.
             */

            background-color: white;

        }


        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: "";

            /**
             * Display.
             */

            display: block;

            /**
             * Padding.
             */

            padding-bottom: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-bottom: 72%

            }

        }

        /**
         * li.
         */

        li {

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 0;

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

            /**
             * Font related.
             */

            text-align: center;

            /**
             * Transition.
             */

            transition: opacity 0.2s ease;

            /**
             * &.hide.
             */

            &.hide {

                /**
                 * Opacity.
                 */

                opacity: 0;

            }

        }

    }

    /**
     * .slide-selector-list.
     */

    .slide-selector-list {

        /**
         * li.
         */

        li {
          
            /**
             * &.active.
             */

            &.active {

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: #1f5c7f;

                }

            }

        }

    }

    /**
     * .swiper-wrapper.
     */

    .swiper-wrapper {

        /**
         * .swiper-slide.
         */

        .swiper-slide {
    
            /**
             * Padding.
             */

            padding-right: 100px;
            padding-bottom: 10px;

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Padding.
                 */

                padding-right: 15px !important;

            }

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                // color: green;

                text-align: left;

            }

            /**
             * h2.
             */

            h2 {

                /**
                 * Font related.
                 */

                font-size: 29px;
                color: $brand-three;
                line-height: 36px;

                /**
                 * Margin.
                 */

                margin-bottom: 15px;

            }

            /**
             * h3.
             */

            h3 {

                /**
                 * Font related.
                 */

                font-size: 23px;
                color: $brand-three;
                line-height: 29px;

                /**
                 * Margin.
                 */

                margin-bottom: 15px;

            }

            /**
             * h4.
             */

            h4 {

                /**
                 * Font related.
                 */

                font-size: 19px;
                color: $brand-five;

                /**
                 * Margin.
                 */

                margin-bottom: 10px;

            }

            /**
             * ul.
             */

            ul {

                /**
                 * 
                 */

                margin-left: 30px;

                /**
                 * li.
                 */

                li {

                    list-style-type: disc;

                    /**
                     * Font related.
                     */

                    line-height: 22px;
                    color: white;

                    /**
                     * Margin.
                     */

                    margin-bottom: 5px;

                }

            }

        }

    }



}
