/**
 * .home-panel-infotwocolumn-wrapper.
 */

.home-panel-infotwocolumn-wrapper {

    /**
     * h2.
     */

    h2 {

        /**
         * Sizing.
         */

        width: 100%;

    }

    /**
     * .reverse-order.
     */

    .reverse-order {

        /**
         * Flex.
         */

        flex-direction: row-reverse;

        /**
         * .col-text.
         */

        .col-text {

            /**
             * Padding.
             */

            padding-right: 0;
            padding-left: 80px;

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Sizing.
                 */

                padding-left: 15px;
                padding-right: 15px;

            }

        }
        
    }

    /**
     * .inline-reverse-order.
     */
    
    .inline-reverse-order {

        /**
         * .reverse-order.
         */

        .reverse-order {

            /**
             * @include.
             */
    
            @include breakpoint($b-df-mobile-landscape) {
    
                /**
                 * Flex.
                 */
    
                flex-direction: column-reverse !important;
    
            }

        }

    }

    /**
     * .col-text.
     */

    .col-text {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        /**
         * &.inset-text.
         */

        &.inset-text {

            /**
             * Padding.
             */

            padding-right: 80px;

        }

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 19px;
            line-height: 28px;

        }

        /**
         * .button.
         */

        .button {

            margin-top: 20px;

        }

        /**
         * ul.
         */

        ul {

            /**
             * List.
             */

            list-style: none;

            /**
             * Margin.
             */

            margin-left: 22px;

            /**
             * Font related.
             */
        
            text-align: left;

            /**
             * li.
             */

            li {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Margin.
                 */

                margin-bottom: 10px;

                /**
                 * Font related.
                 */

                font-size: 19px;
                line-height: 28px;

                /**
                 * Padding.
                 */
            
                padding-left: 8px;

                /**
                 * &::before.
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: 9px;
                    left: -20px;

                    /**
                     * Sizing.
                     */

                    width: 8px;
                    height: 8px;

                    /**
                     * Border.
                     */

                    border-radius: 10px;

                    /**
                     * Background.
                     */

                    background-color: $brand-one;

                }
                
            }

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {

            /**
             * Padding.
             */

            padding-right: 15px !important;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Flex.
             */

            align-items: center;

        }

    }

    /**
     * .col-graphic.
     */

    .col-graphic {

        
        /**
         * @include.
         */
        
        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Padding.
             */
            
            padding-top: 25px;
            padding-bottom: 20px;

            /**
             * Font related.
             */
    
            text-align: center;
            
        }

        /**
         * .image, object.
         */

        .image, object {

            /**
             * Sizing.
             */

            width: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                width: 80%;

            }

        }

        /**
         * .image.
         */

        .image {

            /**
             * Margin.
             */

            margin-bottom: 10px;

            /**
             * Sizing.
             */

            max-width: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Padding.
                 */

                width: auto;

            }

        }

    }

}
