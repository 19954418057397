/**
 * .common-panel-single-column-content-wrapper.
 */

.common-panel-single-column-content-wrapper {

    /**
     * .container.
     */

    .container {

        /**
         * Background.
         */

        // background-color: cornflowerblue;

    }

}

/**
 * .single-content-column-container.
 */

.single-content-column-container {

    /**
     * p.
     */

    p {

        /**
         * Margin.
         */

        margin-bottom: 10px;

        /**
         * Font related.
         */

        font-size: 16px;
        line-height: 22px;

    }

    /**
     * h2, h3, h4.
     */

    h2, h3, h4 {

        /**
         * Font related.
         */

        font-weight: bold;

    }

    /**
     * h2.
     */

    h2 {

        /**
         * Margin.
         */

        margin-bottom: 10px;

    }

    /**
     * h3.
     */

    h3 {

        /**
         * Margin.
         */

        margin-bottom: 10px;
        
    }

    /**
     * p + h3.
     */

    p + h3 {

        /**
         * Margin.
         */

        margin-top: 16px;

    }

    /**
     * p + h2.
     */

    p + h2 {

        /**
         * Margin.
         */

        margin-top: 16px;

    }
    
    /**
     * p + h4.
     */

    p + h4 {

        /**
         * Margin.
         */

        margin-top: 16px;

    }

    /**
     * .content-image.
     */

    .content-image {

        /**
         * Padding.
         */

         padding-bottom: 20px;

        /**
         * img.
         */

        img {
            
            /**
             * Sizing.
             */

            max-width: 100%;

        }

        /**
         * .caption-container.
         */

        .caption-container {

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 12px;

            }

        }

    }

    /**
     * .content-video.
     */

    .content-video {

        /**
         * iframe.
         */

        iframe {

            /**
             * Sizing.
             */

            max-width: 100%;

        }

    }

    /**
     * .content-space.
     */

    .content-space {

        /**
         * &.size-1.
         */

        &.size-1 { height: 20px; }
        &.size-2 { height: 40px; }
        &.size-3 { height: 60px; }
        
    }

    /**
     * .content-list.
     */

    .content-list {

        /**
         * ul.
         */

        ul {

            /**
             * Margin.
             */

            margin-left: 15px;

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                color: $brand-two !important;

            }

        }

    }

    /**
     * .content-table.
     */

    .content-table {

        /**
         * Margin.
         */

        margin-top: 20px;
        margin-bottom: 40px;

        /**
         * table.
         */

        table {

            /**
             * Sizing.
             */

            width: 100%;

            /**
             * &.columns-1.
             */

            &.columns-1 { 
                th { width: 100%; }
                td { width: 100%; } 
            }

            /**
             * &.columns-2.
             */

            &.columns-2 { 
                th { width: 50%; }
                td { width: 50%; } 
            }

            /**
             * &.columns-3.
             */

            &.columns-3 { 
                th { width: 33.3%; }
                td { width: 33.3%; } 
            }

            /**
             * &.columns-4.
             */

            &.columns-4 { 
                th { width: 25%; }
                td { width: 25%; } 
            }

        }

        /**
         * tr.
         */

        tr {

            /**
             * th.
             */

            th {

                /**
                 * Border.
                 */

                border: 1px solid $brand-three;

                /**
                 * Padding.
                 */

                padding: 15px 15px 10px 15px;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-two;
                    font-size: 16px;

                }

            }

        }

        /**
         * tr.
         */

        tr {

            /**
             * td.
             */

            td {

                /**
                 * Border.
                 */

                border: 1px solid $brand-three;

                /**
                 * Padding.
                 */

                padding: 15px 15px 10px 15px;

            }

        }

    }

    /**
     * .content-button.
     */

    .content-button {

        /**
         * 
         */

    }

}
