/**
 * .social-icons-list.
 */

.social-icons-list {

    /**
     * li.
     */

    li {

        /**
         * Display.
         */

        display: inline-block;

    }

    /**
     * a.
     */

    a {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Margin.
         */

        margin-right: 10px;

        /**
         * img, svg.
         */

        img, svg {

            /**
             * Sizing.
             */

            width: 22px;

            /**
             * Transform.
             */

            transform: translateY(-2px);

        }

        /**
         * svg.
         */

        svg {

            /**
             * path.
             */

            path {

                /**
                 * Fill.
                 */

                fill: $brand-three;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * path.
                 */

                path {
                    
                    /**
                     * Fill.
                     */

                    fill: $brand-three !important;

                }

            }

        }

    }

}
