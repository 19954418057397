// Remember: do NOT edit this file.

$brand-five: #1f5c7f;

$brand-four: #fafafa;

$brand-three: #ffffff;

$brand-two: #010101;

$brand-one: #02aef0;
